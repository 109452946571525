// DisplayNameWithStatusIndicator.js
import { connect } from 'react-redux';
import DisplayNameWithStatusIndicator from '../component/DisplayNameWithStatusIndicator.jsx';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getUserData from '../selector/getUserData.js';
import fetchUser from '../action/fetchUser.js';
import { FEATURE_DISPLAY_NAME } from '../RemoteConfigKeys.js';

const mapStateToProps = (state, { id }) => {
  return {
    username: getUserData(state, id, 'username'),
    displayName: getUserData(state, id, 'displayName'),
    isDisplayNameEnabled:
      getRemoteConfigData(state, FEATURE_DISPLAY_NAME) === 1,
    isUserFetching: id
      ? getNetworkingData(state, ['user', id], 'isFetching')
      : false,
    isUserFetched: id
      ? getNetworkingData(state, ['user', id], 'isFetched')
      : false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: data => dispatch(fetchUser(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayNameWithStatusIndicator);
