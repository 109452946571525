// UserCategory.js
import { connect } from 'react-redux';

import withRouter from '../component/WithRouter.jsx';
import UserCategory from '../component/UserCategory.jsx';
import fetchFeeds from '../action/fetchFeeds.js';
import fetchFollowing from '../action/fetchFollowing.js';
import getListData from '../selector/getListData.js';
import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getOperationData from '../selector/getOperationData.js';
import subscribeFeedChannel from '../action/subscribeFeedChannel.js';
import unsubscribeFeedChannel from '../action/unsubscribeFeedChannel.js';
import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';
import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_USER,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';

const mapStateToProps = (state, { category, isFollowing }) => {
  const selectPath = isFollowing ? ['following'] : ['home', 'users', category];
  const userIds = getListData(state, selectPath, 'itemIds');
  const nextPage = getListData(state, selectPath, 'nextPage');
  const isNextPageFetching = !!getNetworkingData(
    state,
    [...selectPath, nextPage],
    'isFetching'
  );
  const isFirstPageFetched = !!getNetworkingData(
    state,
    [...selectPath, 1],
    'isFetched'
  );

  const isAuthed = !!getMeData(state, 'id');
  const isRemoteConfigMerged = isAuthed
    ? getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_USER)
    : getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_CLIENT) ||
      getIsRemoteConfigMerged(state, CONFIG_PRIORITY_CONFIGURE);
  const swaggerOnlineFeed = getOperationData(
    state,
    ['swaggerOnlineFeed'],
    'search'
  );

  return {
    meId: getMeData(state, 'id'),
    category,
    userIds,
    isAuthed,
    isFirstPageFetched,
    isNextPageFetching,
    nextPage,
    deviceWidth: getOperationData(state, ['device'], 'width'),
    isRemoteConfigMerged,
    swaggerOnlineFeed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    fetchFollowing: ({ page }) => dispatch(fetchFollowing({ page })),
    subscribeFeedChannel: ({ feedName }) =>
      dispatch(subscribeFeedChannel({ feedName })),
    unsubscribeFeedChannel: ({ feedName }) =>
      dispatch(unsubscribeFeedChannel({ feedName })),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserCategory)
);
