// UserFeedItem.js
import { connect } from 'react-redux';

import getUserData from '../selector/getUserData.js';
import UserFeedItem from '../component/UserFeedItem.jsx';
import sendChatStart from '../action/sendChatStart.js';

function mapStateToProps(state, { id }) {
  return {
    username: getUserData(state, id, 'username'),
  };
}
const mapDispatchToProps = dispatch => {
  return {
    sendChatStart: ({ userId }) => dispatch(sendChatStart({ userId })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserFeedItem);
